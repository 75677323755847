<template>
  <v-container class="white">
    <h4>{{ $t('general.documents') }}</h4>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('general.general_information') }}
          </v-card-title>
          <v-card-text>
            <DocumentList :i_DocumentType="18" :i_UploadFile="1"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DocumentList from "../../../components/general/DocumentList.vue";
export default {
  name: "ExpertDocuments",
  components: {
    DocumentList,
  },
  data: function () {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style>
</style>